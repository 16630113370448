import React, { useState, useEffect, useRef } from "react";

import Graph from "react-graph-vis";
import 'vis-network/dist/dist/vis-network.css';

import styles from "../../styles";

import MessageAlert from "../../components/MessageAlert";
import { handleResponse } from "../../utils/tools";
import { data } from "vis-network";

const pageStyle = { ...styles.fullDimensions, ...styles.container }

function nodeBinarySearch(arr, target) {
    let low = 0;
    let high = arr.length - 1;

    while (low <= high) {
        let mid = Math.floor((low + high) / 2);
        const cmp = arr[mid].id.localeCompare(target)
        if (cmp === 0) {
            return arr[mid]; // Element found
        } else if (cmp < 0) {
            low = mid + 1; // Search in the right half
        } else {
            high = mid - 1; // Search in the left half
        }
    }

    return null; // Element not found
}

const InsightsNetwork = ({ token }) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [nodes, setNodes] = useState([])
    const [edges, setEdges] = useState([])
    const [graph, setGraph] = useState({ nodes: [], edges: [] })
    // const graph = {
    //     nodes: [
    //         { id: 1, label: "Node 1", title: "node 1 tootip text" },
    //         { id: 2, label: "Node 2", title: "node 2 tootip text" },
    //         { id: 3, label: "Node 3", title: "node 3 tootip text" },
    //         { id: 4, label: "Node 4", title: "node 4 tootip text" },
    //         { id: 5, label: "Node 5", title: "node 5 tootip text" }
    //     ],
    //     edges: [
    //         { from: 1, to: 2 },
    //         { from: 1, to: 3 },
    //         { from: 2, to: 4 },
    //         { from: 2, to: 5 }
    //     ]
    // };

    const options = {
        autoResize: true,
        layout: {
            randomSeed: 7,
            hierarchical: {
                enabled: true,
                direction: 'LR',        // UD, DU, LR, RL
                sortMethod: 'hubsize',  // hubsize, directed
                shakeTowards: 'leaves'  // roots, leaves
            }
        },
        edges: {
            color: "#000000"
        },
        locale: 'en',
        manipulation: {
            enabled: true,
            initiallyActive: false,
        },
        physics: {
            enabled: false,
            barnesHut: {
                theta: 0.5,
                gravitationalConstant: -2000,
                centralGravity: 0.3,
                springLength: 95,
                springConstant: 0.04,
                damping: 0.09,
                avoidOverlap: 0
            },
            forceAtlas2Based: {
                theta: 0.5,
                gravitationalConstant: -50,
                centralGravity: 0.01,
                springConstant: 0.08,
                springLength: 100,
                damping: 0.4,
                avoidOverlap: 0
            },
            repulsion: {
                centralGravity: 0.2,
                springLength: 200,
                springConstant: 0.05,
                nodeDistance: 100,
                damping: 0.09
            },
            hierarchicalRepulsion: {
                centralGravity: 0.0,
                springLength: 100,
                springConstant: 0.01,
                nodeDistance: 120,
                damping: 0.09,
                avoidOverlap: 0
            },
            maxVelocity: 50,
            minVelocity: 0.1,
            solver: 'barnesHut',
            stabilization: {
                enabled: true,
                iterations: 1000,
                updateInterval: 100,
                onlyDynamicEdges: false,
                fit: true
            },
            timestep: 0.5,
            adaptiveTimestep: true,
            wind: { x: 0, y: 0 }
        }
    };

    const events = {
        select: function (event) {
            var { nodes, edges } = event;
        }
    };

    useEffect(() => {
        if (errorMessage !== '')
            setTimeout(() => setErrorMessage(''), 5000)
        if (infoMessage !== '')
            setTimeout(() => setInfoMessage(''), 3000)
    }, [errorMessage, infoMessage])

    useEffect(() => {
        let nodeArray = []
        setIsLoading(true);
        fetch(`/kg/all/nodes?token=${token}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', }),
        }).then(response => handleResponse(response))
            .then(result => knowledgeVertexToNodes(result, nodeArray))
            .then(_ => fetch(`/kg/all/edges?token=${token}`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', }),
            })).then(response => handleResponse(response))
            .then(result => knowledgeEdgesToEdges(result, nodeArray))
            .then(_ => setIsLoading(false))
            .catch(error => {
                setIsLoading(false);
                console.error(error);
                setErrorMessage(error.message);
            });
    }, [token])

    const knowledgeVertexToNodes = (data, nodeArray) => {
        if (data.result) {
            const n = data.result.map(v => { return { id: v.uuid, label: v.id, level: 0 } }).sort((a, b) => a.id.localeCompare(b.id));
            nodeArray.push(...n);
        } else {
            throw Error("Invalid graph data: no vetices");
        }
    }

    const knowledgeEdgesToEdges = (data, nodeArray) => {
        if (data.result) {
            const e = data.result.map(edge => { return { from: edge.source, to: edge.id, source: edge.label } });
            e.forEach(edge => {
                let node = nodeBinarySearch(nodeArray, edge.to);
                if (edge.source === "<_source_>") {
                    node.level += 2
                } else {
                    node.level += 1
                }
            });
            nodeArray.forEach(node => {
                if (node.level === 0 || node.label.startsWith("_:")) {
                    node.title = node.label;
                    node.label = '';
                    node.hidden = false;
                }
            })
            setGraph({ nodes: [...nodeArray], edges: e });
            //console.log(nodeArray)
        } else {
            throw Error("Invalid graph data: no edges");
        }
    }

    return (
        <main style={pageStyle}>
            <MessageAlert message={errorMessage} setMessage={setErrorMessage} />
            <MessageAlert message={infoMessage} setMessage={setInfoMessage} severity={"info"} />
            <Graph
                graph={graph}
                options={options}
                events={events}
                getNetwork={network => {
                    //  if you want access to vis.js network api you can set the state in a parent component using this property
                }}
                style={{ height: '85vh' }}
            />
        </main>
    );
}

export default InsightsNetwork;