const api_root = ''; //process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT : window.location.origin;

const URL = 'URL', PDF = 'PDF', MMLD = "MMLD"
const IS_DARK_MODE = 'is-dark-mode'

const FILE_SUBMIT_ENDPOINT = api_root + '/filesubmit'
const MMLD_SUBMIT_ENDPOINT = api_root + '/mmldsubmit'
const URL_SUBMIT_ENDPOINT = api_root + '/urlsubmit'
const EMBED_ENDPOINT = api_root + '/embed'
const LIST_SECTIONS_ENDPOINT = api_root + '/sections/list'
const ADD_SECTION_ENDPOINT = api_root + '/sections/add'
const DELETE_SECTION_ENDPOINT = api_root + '/sections/delete'
const UPDATE_SECTION_ENDPOINT = api_root + '/sections/update'
const LIST_LABELS_ENDPOINT = api_root + '/sections/labels/list'
const ADD_LABEL_ENDPOINT = api_root + '/sections/labels/add'
const DELETE_LABEL_ENDPOINT = api_root + '/sections/labels/delete'
const UPDATE_LABEL_ENDPOINT = api_root + '/sections/labels/update'
const PSO_SUBMIT_ENDPOINT = api_root + '/psosubmit'
const CREATE_PRODUCT_LINE_KEYS_ENDPOINT = api_root + '/productlines/keys'
const POST_ACCESS_REQUEST_ENDPOINT = api_root + '/auth/request'
const IS_VALID_TOKEN_ENDPOINT = api_root + '/auth/token/valid'
const EVENT_STREAM_WEBSOCKET_ENDPOINT = window.location.origin.replace('http', 'ws') + '/events'

const PageNames = {
    CHAT: 'chat-page',
    USER_MANGEMENT: 'user-management',
    STORAGE_MANAGEMENT: 'storage-management',
    INSIGHTS_NETWORK: 'insights-network'
}

const ErrorMessages = {
    ADD_SECTION_ERROR: 'Unable to add section',
    UPDATE_SECTION_ERROR: 'Unable to update section',
    DELETE_SECTION_ERROR: 'Unable to delete sections',
    FETCH_SECTIONS_ERROR: 'Unable to get sections',
    FETCH_LABELS_ERROR: 'Unable to get labels',
    DELETE_LABELS_ERROR: 'Unable to delete labels',
    UPDATE_LABEL_ERROR: 'Unable to update label',
    ADD_LABEL_ERROR: 'Unable to add label',

    CONTEST_INGESTION_REQUEST_ERROR: 'Unable to get response from the server',
    CONTENT_INGESTION_RESPONSE_ERROR: 'Server was not able to ingest the content, try again',
    PSO_EXTRACTION_REQUEST_ERROR: 'Unable to get response from the server',
    PSO_EXTRACTION_TRY_AGAIN: 'Try again, unable to get PSO file format',
    PSO_EXTRACTION_RESPONSE_ERROR: 'Server was not able to parse the PSO file, try again',
    PRODUCT_LINE_KEYS_GENERATION_REQUEST_ERROR: 'Unable to get response from the server',
    PRODUCT_LINE_KEYS_GENERATION_RESPONSE_ERROR: 'Unable to generate keys for product line',

    NO_EMAIL_IN_ACCESS_REQUEST_ERROR: 'Email address is required',
    INVALID_EMAIL_IN_ACCESS_REQUEST_ERROR: 'Please provide a valid email address',
    ACCESS_REQUEST_ERROR: 'Unable to send access request',

    ACCESS_REQUEST_SUBMITTED: 'Request submitted!'
}

const SocketMessages = {
    FINISHED_PREPARING_URL: 'Prepared url data',
    FINISHED_PREPARING_FILE: 'Prepared file data',
    FINISHED_PAGE_NUMBER: 'Parsed pages',
    FINISHED_TURNING_TO_SECTIONS: 'Got sections from page ',
    FINISHED_LABELLING_SECTION: 'Assigned section label',
    CONNECTED_TO_SOCKET: 'Socket connection started',
    STARTED_PARSING_PRODUCT_LINE: 'Parsing product line',
    FINISHED_PARSING_PRODUCT_LINE: 'Done parsing',
    FINISHED_GETTING_PRODUCT_LINE_PRODUCTS: 'Products processed',
    FINISHED_GETTING_PRODUCT_LINE_PRODUCTS_KEYWORDS: 'Processing keys',
    FINISHED_GETTING_PRODUCT_LINE_PRODUCTS_KEYWORDS_DESCRIPTIONS: 'Processing descriptions',
    FINISHED_MERGING_PRODUCT_KEYWORDS: 'Merged product keys',
    FINISHED_MERGING_PRODUCT_KEYWORDS_DESCRIPTIONS: 'Merged key descriptions',
    FAILED_GETTING_PRODUCT_LINE_KEYWORDS: 'Failed to get keys'
}

export {
    api_root,
    URL,
    PDF,
    MMLD,
    IS_DARK_MODE,
    MMLD_SUBMIT_ENDPOINT,
    FILE_SUBMIT_ENDPOINT,
    URL_SUBMIT_ENDPOINT,
    EMBED_ENDPOINT,
    LIST_SECTIONS_ENDPOINT,
    ADD_SECTION_ENDPOINT,
    DELETE_SECTION_ENDPOINT,
    UPDATE_SECTION_ENDPOINT,
    ADD_LABEL_ENDPOINT,
    DELETE_LABEL_ENDPOINT,
    UPDATE_LABEL_ENDPOINT,
    LIST_LABELS_ENDPOINT,
    PSO_SUBMIT_ENDPOINT,
    CREATE_PRODUCT_LINE_KEYS_ENDPOINT,
    POST_ACCESS_REQUEST_ENDPOINT,
    IS_VALID_TOKEN_ENDPOINT,
    EVENT_STREAM_WEBSOCKET_ENDPOINT,
    PageNames,
    ErrorMessages,
    SocketMessages
}
