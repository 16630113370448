import React, { useEffect, useState } from "react";
import { IS_VALID_TOKEN_ENDPOINT, PageNames } from "../settings";
import {
    Box,
    createTheme,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    LinearProgress,
    ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import StorageIcon from "@mui/icons-material/Storage";
import PeopleIcon from '@mui/icons-material/People';
import InsightsIcon from '@mui/icons-material/Insights';

import styles from "../styles";

import ApplicationLogo from "../components/ApplicationLogo";
import BottomDivider from "../components/BottomDivider";
import DarkModeSwitch from "../components/DarkModeSwitch";
import ChatPage from "./landingpage/ChatPage";
import UserTable from "./landingpage/UserTable";
import InsightsNetwork from "./landingpage/InsightsNetwork";
import StorageList from "./storage/StorageList";

import { Navigate } from "react-router-dom";
import getUrlWithToken from "../utils/getUrlWithToken";

const TopScrollBounceBackgroundFix = ({ isDarkMode, darkTheme }) => <div style={{ height: 1000, width: '100%', position: 'fixed', marginTop: -999, backgroundColor: isDarkMode ? darkTheme.palette.background.default : darkTheme.palette.primary.main }} />
const BottomScrollBounceBackgroundFix = ({ darkTheme, currentPage }) => <div style={{ height: 1000, width: '100%', position: 'fixed', marginTop: currentPage === PageNames.PSO_EXTRACTION ? -83 : -20, backgroundColor: darkTheme.palette.background.default }} />

const LandingPage = ({ token, isDarkMode, setIsDarkMode }) => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false)
    const [hasAccess, setHasAccess] = useState(null)

    const [currentPage, setCurrentPage] = useState(PageNames.CHAT)

    const darkTheme = createTheme({
        palette: {
            mode: isDarkMode ? 'dark' : 'light'
        }
    });

    const isValidToken = async (_token) => {
        try {
            const response = await fetch(getUrlWithToken(IS_VALID_TOKEN_ENDPOINT, _token))
            if (!response?.ok) {
                return false
            } else {
                const { isValid } = await response.json()
                return isValid
            }
        } catch (e) {
            console.log("Error: ", e)
            return false
        }
    }

    useEffect(() => {
        if (token)
            isValidToken(token)
                .then(isValid => setHasAccess(isValid))
        else {
            setHasAccess(false)
        }
    }, [token])

    return hasAccess === null ? <Box>
        <TopScrollBounceBackgroundFix isDarkMode={isDarkMode} darkTheme={darkTheme} />
        <AppBar position="fixed" style={styles.appbar}>
            <Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" noWrap>
                        consumer-tech.ai
                    </Typography>
                    <ApplicationLogo />
                </div>
            </Toolbar>
        </AppBar>
        <main style={styles.main}>
            <LinearProgress />
        </main>
        <BottomScrollBounceBackgroundFix darkTheme={darkTheme} currentPage={currentPage} />
    </Box> : hasAccess ? (
        <Box>
            <TopScrollBounceBackgroundFix isDarkMode={isDarkMode} darkTheme={darkTheme} />
            <AppBar position="fixed" style={styles.appbar}>
                <Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" noWrap>
                            Consumer-Tech.ai
                        </Typography>
                        <ApplicationLogo />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant='persistent'
                anchor='left'
                open={isNavbarOpen}
                sx={{
                    width: 360,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: 360, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', height: '100%' }}>
                    <List>
                        <ListItem key={0} disablePadding>
                            <ListItemButton onClick={() => {
                                setIsNavbarOpen(false)
                                setCurrentPage(PageNames.CHAT)
                            }}>
                                <ListItemIcon>
                                    <AdfScannerIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Chat'} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={1} disablePadding>
                            <ListItemButton onClick={() => {
                                setIsNavbarOpen(false)
                                setCurrentPage(PageNames.INSIGHTS_NETWORK)
                            }}>
                                <ListItemIcon>
                                    <InsightsIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Insights'} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem key={3} disablePadding>
                            <ListItemButton onClick={() => {
                                setIsNavbarOpen(false)
                                setCurrentPage(PageNames.USER_MANGEMENT)
                            }}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary={'User Management'} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={4} disablePadding>
                            <ListItemButton onClick={() => {
                                setIsNavbarOpen(false)
                                setCurrentPage(PageNames.STORAGE_MANGEMENT)
                            }}>
                                <ListItemIcon>
                                    <StorageIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Storage Management'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <BottomDivider />
                <DarkModeSwitch isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
            </Drawer>
            <main style={styles.main}>
                {currentPage === PageNames.CHAT && <ChatPage token={token} />}
                {currentPage === PageNames.USER_MANGEMENT && <UserTable token={token} />}
                {currentPage === PageNames.STORAGE_MANGEMENT && <StorageList token={token} />}
                {currentPage === PageNames.INSIGHTS_NETWORK && <InsightsNetwork token={token} />}
            </main>
            <BottomScrollBounceBackgroundFix darkTheme={darkTheme} currentPage={currentPage} />
        </Box>
    ) : <Navigate to={'/login'} />
}

export default LandingPage
