import React, { useState, useEffect } from 'react'
import styles from "../styles";
import AppBar from "@mui/material/AppBar";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import ApplicationLogo from "../components/ApplicationLogo";
import TextField from "@mui/material/TextField";
import FormLoadingBar from "../components/FormLoadingBar";
import MessageAlert from "../components/MessageAlert";
import { ErrorMessages, POST_ACCESS_REQUEST_ENDPOINT } from "../settings";

// TODO fix bg on scroll bounce
const RequestAccess = () => {
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [requestSubmitted, setRequestSubmitted] = useState(false)

    useEffect(() => {
        if (errorMessage !== '') setTimeout(() => setErrorMessage(''), 3000)
        if (infoMessage !== '') setTimeout(() => setInfoMessage(''), 3000)
    }, [errorMessage, infoMessage])

    const sendAccessRequest = async (body) => {
        let rawResponse
        try {
            rawResponse = await fetch(POST_ACCESS_REQUEST_ENDPOINT, {
                method: 'POST',
                body: body
            })
            if (rawResponse?.ok) {
                setInfoMessage(ErrorMessages.ACCESS_REQUEST_SUBMITTED)
                setRequestSubmitted(true)
                return true
            } else
                setErrorMessage(ErrorMessages.ACCESS_REQUEST_ERROR)
        } catch (error) {
            console.log("Error: ", error)
            setErrorMessage(ErrorMessages.ACCESS_REQUEST_ERROR)
        }
        return false
    }

    const formBuilder = async () => {
        const formData = new FormData()
        await formData.append("email", email)
        return formData
    }

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
        return re.test(email);
    };

    const validateInput = () => {
        if (!email) {
            setErrorMessage(ErrorMessages.NO_EMAIL_IN_ACCESS_REQUEST_ERROR)
            return false
        } else if (!validateEmail(email)) {
            setErrorMessage(ErrorMessages.INVALID_EMAIL_IN_ACCESS_REQUEST_ERROR)
            return false
        } else {
            return true
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (validateInput()) {
            const formData = await formBuilder()
            setIsLoading(true)

            await sendAccessRequest(formData)

            setIsLoading(false)
        }
    }

    const handleKeyPress = (ev) => {
        if (email && ev.key === 'Enter') {
            handleSubmit(ev);
        }
    }

    return <main style={styles.main}>
        <AppBar position="fixed" style={styles.appbar}>
            <Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" noWrap>
                        Consumer-Tech.ai
                    </Typography>
                    <ApplicationLogo />
                </div>
            </Toolbar>
        </AppBar>
        <Toolbar />
        {requestSubmitted ?
            (
                <Box style={styles.container}>
                    <Typography style={styles.headline} variant="h5">
                        Your access request is being processed. An access link will be emailed to you shortly.
                    </Typography>
                </Box>
            ) :
            (
                <Box style={styles.container}>
                    <Typography style={styles.headline} variant="h5">
                        Access request
                    </Typography>
                    <MessageAlert message={errorMessage} setMessage={setErrorMessage} />
                    <MessageAlert message={infoMessage} setMessage={setInfoMessage} severity={'info'} />
                    <Box style={styles.subcontainer}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <form>
                                <TextField type="email" name="email" variant="outlined" style={{ marginRight: 16, width: 320 }}
                                    onChange={(event) => setEmail(event.target.value.toLowerCase())}
                                    onKeyPress={handleKeyPress}
                                    disabled={isLoading} label={'email@domain.com'} />
                                <Button
                                    variant="contained"
                                    style={{ height: "100%", width: 200 }}
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    Submit
                                </Button>
                            </form>
                            {isLoading && <FormLoadingBar lastMessage={null} />}
                        </div>
                    </Box>
                </Box>
            )
        }
    </main>
}

export default RequestAccess
